
//NEED TO FIND NEW AFFIX METHOD
//
//jQuery( document ).ready(function($) {
//jQuery('#nav').affix({
//      offset: {
//        top: $('#sect-video').height()-50
//      }
//
//});
//});

(function($) {
        function e(e, o) {
            var a = "translateY(" + o + "px";
            e.style["-webkit-transform"] = a, e.style["-moz-transform"] = a, e.style["-ms-transform"] = a, e.style["-o-transform"] = a, e.style.transform = a
        }


var $window = $(window);
var nav = $('#header-main');
var logo = $('#logo');
var navSecondary = $('#header-secondary');
var mobileLines = $('#mobile-lines');
var s;
//only fire skrollr when we are on browsers bigger than 72em
    // enquire.register("screen and (min-width: 72em)", {
    //     match: function () {
    //         var s = skrollr.init({forceHeight: false});
    //     },
    //     unmatch: function () {
    //         var s = skrollr.destroy();
    //     },
    //     deferSetup: true,
    // });


//     $window.scroll(function(){
//     //menu bar is 3ems high so we stick it once we get to the length of the video - 3ems
//     if ($($window.scrollTop()).toEm() >= 1) {
//         mobileLines.addClass('affix');
//             nav.removeClass('fade-up ');
//          nav.addClass('affix ');
//         nav.addClass('fade-down ');
//         logo.addClass('affix');
//
//     }
//     if ($window.scrollTop() == 0) {
//         mobileLines.removeClass('affix');
//         nav.removeClass('fade-down');
//         nav.addClass('fade-up ');
//         nav.removeClass('affix');
//         logo.removeClass('affix');
//         }
//
//
// });

    if ($("#trigger-nav, .lines-button, .closed, .siteoverlay").click(function(e) {
                $("body, nav, .lines-button ").toggleClass("js-nav-open close"), e.preventDefault()
            }),
            $(".wpcf7-list-item-label").click(function(e) {
                $(this).toggleClass("checked"), e.preventDefault()
            }),
            $(".wpcf7-list-item-label, label").bind("click", function() {
                var e = $(this).find(":checkbox"),
                    o = $(this).find(":radio");
                e.attr("checked", !e.attr("checked")), o.attr("checked", !o.attr("checked"))
            }),

            $("#sect-intro-video").length > 0) {
            var o = function() {
                var o = document.getElementById("sect-intro-video"),
                    a = window.scrollY / 2;
                e(o, a)
            };
            $(document).ready(function() {
                $(window).on("scroll", o)
            })
        }
 })( jQuery );


  //bio popups
 ( function($) {
    $(document).ready(function() {
        var bioNum = 0;
        var revealContent = '';

        $('.btn-reveal').on('click', function() {
            bioNum = $(this).data("id");
            revealContent = 'reveal'+bioNum;
            $(this).toggleClass('active');
            $('.'+revealContent).toggleClass('show');
            $('button[data-id='+bioNum+']').toggleClass('open');

            closeButton();
        });
        function closeButton(){
            $('button[data-id='+bioNum+']').on('click', function() {
                $(this).removeClass('open');
                $('.btn-reveal').removeClass('active');
                $('.reveal-content').removeClass('show');
            });
        }
    });
} )( jQuery );



jQuery.fn.refresh = function() {
    var s = skrollr.get();

    if(s) {
        s.refresh(this);
    }

    return this;
};
